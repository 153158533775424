import revive_payload_client_YTGlmQWWT5 from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_fWYXZjzQhM from "/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_VTarsGAzgb from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_PKIXU5KmsI from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/website/careers/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_5kIVOIwwOW from "/app/node_modules/@nuxtjs/algolia/dist/runtime/plugin.mjs";
import gsapPlugin_HlwIIyl85L from "/app/website/careers/.nuxt/gsapPlugin.mjs";
import plugin_FweqFnuQMW from "/app/website/core/modules/3.seo-scripts/gtm/plugin.ts";
import switch_locale_path_ssr_lCxu0ZmxIx from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_5C7QFp7tsq from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import i18n_client_3ZFZ8VF6Mh from "/app/website/core/plugins/i18n.client.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  _0_siteConfig_fWYXZjzQhM,
  payload_client_VTarsGAzgb,
  navigation_repaint_client_PKIXU5KmsI,
  check_outdated_build_client_LIYcCMJD18,
  chunk_reload_client_SeG0EjL5Ec,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  plugin_5kIVOIwwOW,
  gsapPlugin_HlwIIyl85L,
  plugin_FweqFnuQMW,
  switch_locale_path_ssr_lCxu0ZmxIx,
  i18n_5C7QFp7tsq,
  i18n_client_3ZFZ8VF6Mh
]